import React, { useState } from "react";
import moment from "moment";
import Toggle from "../form/Toggle";
import ToggleHeader from "../form/ToggleHeader";
import Modal from "../shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import Button from "../form/Button";
import { ForwardIcon } from "@heroicons/react/24/outline";
import { formatCurrency } from "src/helpers";

const PlanResubscribe = ({ onHold, activePlan, interval, setInterval, plan, workspaceDetails, setPaymentDetails, setOnHold, setView }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [resubscribeConfirmation, setResubscribeConfirmation] = useState(false);

  const handleSubscribe = async () => {
    try {
      setButtonLoading(true);
      const response = await apiRequest("post", `/portal/workspaces/:workspace_id/billing/payment/intent`, { body: { interval: activePlan?.subscription?.recurring_type }, params: { workspace_id: workspaceDetails?._id } });
      if (response?.data?.status === 200) {
        setResubscribeConfirmation(false);
        setPaymentDetails(response?.data?.data);
        setView("payForm");
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setButtonLoading(false);
    }
  };
  return (
    <div>
      <div className="flex items-center justify-between border-b px-2 py-1 text-base font-normal text-slate-600">
        {activePlan?.subscription_status === "on_trial" && `Your free trial will expire in ${activePlan?.free_trial_days_left} day(s). To avoid automatic expiration, please complete your payment.`}
        {activePlan?.subscription_status === "canceled" && (
          <div>
            Your subscription has been canceled. Your current plan will expire on <span className="font-medium text-highlightColor">{moment(activePlan?.end_date).format("MMM DD, YYYY")}</span>.<br />
            To continue, please renew your subscription.
          </div>
        )}

        {activePlan?.subscription_status === "expired" && `Your subscription has expired. To regain access, please renew your subscription.`}
      </div>
      <div className="relative my-3 flex w-full items-center justify-between rounded-lg border border-gray-200 bg-white px-4 py-3">
        <div className="flex items-center gap-2">
          <Toggle
            checked={interval === "month"}
            onChange={() => setInterval(interval === "month" ? "year" : "month")}
          />
          <div className="flex gap-1">Switch to monthly billing</div>
        </div>
        <div className="flex gap-1">
          <div className="!mt-auto rounded-md border border-gray-200 px-3 py-1 font-bold text-highlightColor">
            {plan?.pricing?.rates?.map((rate) => {
              if (rate.interval !== interval) return null;
              const discountedPrice = rate.amount - rate.discount_amount;
              const showDiscount = rate.discount_type !== "none" && rate.discount_percentage > 0;
              return (
                <div
                  key={rate._id}
                  className="flex items-center justify-between">
                  <div>
                    {showDiscount ? (
                      <>
                        <span className="text-gray-400 line-through">{formatCurrency(rate.amount)}</span>
                        <span className="ml-2 text-green-600">{formatCurrency(discountedPrice)}</span>
                      </>
                    ) : (
                      <span>{formatCurrency(rate.amount)}</span>
                    )}
                    <span className="text-base font-medium text-gray-400"> / {rate.interval}</span>
                  </div>
                  {showDiscount && <span className="ml-2 rounded bg-yellow-100 px-2 py-0.5 text-sm font-medium text-yellow-800">{rate.discount_percentage}% off</span>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end pt-3">
        <Button
          isDisable={buttonLoading}
          buttonClasses="!bg-highlightColor"
          buttonIconPosition={["canceled"].includes(activePlan?.subscription_status) ? "" : "right"}
          buttonIcon={ForwardIcon}
          buttonLabel={["canceled"].includes(activePlan?.subscription_status) ? "Resubscribe" : "Proceed to Payment"}
          buttonLabelClasses={"!font-medium !text-base"}
          buttonFunction={["canceled"].includes(activePlan?.subscription_status) ? () => setResubscribeConfirmation(true) : () => handleSubscribe()}
        />
      </div>
      <ResubscribeModal
        isOpen={resubscribeConfirmation}
        disable={buttonLoading}
        onHold={onHold}
        endDate={moment(activePlan?.end_date).add(1, "days").format("MMM DD, YYYY")}
        setOnHold={setOnHold}
        onDelete={() => {
          handleSubscribe();
        }}
        onCancel={() => setResubscribeConfirmation(false)}
      />
    </div>
  );
};

export default PlanResubscribe;

const ResubscribeModal = ({ endDate, setOnHold, onHold, isOpen = false, disable = false, onDelete = () => {}, onCancel = () => {} }) => {
  return (
    <Modal
      title={"Resubscribe"}
      secondaryTitle="Confirmation"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onDelete}
      defaultOptions={{
        onSuccessButtonText: "Confirm",
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart: disable,
        onCancelButtonVisible: true,
        onCancelButtonText: "Cancel",
      }}>
      <div className="flex items-center gap-2 ">
        <ToggleHeader
          title={`Activate New Plan Immediately`}
          subtitle={
            <div>
              Turn this switch ON to activate the new plan immediately. If left OFF, the new plan will start automatically at <span className="font-semibold text-highlightColor">{endDate}</span> after the end of your current billing period .
            </div>
          }>
          <Toggle
            checked={onHold}
            onChange={(e) => {
              setOnHold((pre) => !pre);
            }}
          />
        </ToggleHeader>
      </div>
    </Modal>
  );
};
