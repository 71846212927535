import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ArrowUturnLeftIcon, BanknotesIcon } from "@heroicons/react/24/outline";
import { apiRequest } from "src/async/apiUtils";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../form/Button";
import { formatCurrency } from "src/helpers";

const CardForm = ({ onHold, interval, setView, workspaceDetails, paymentDetails, refresh = () => {}, planDetails }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const onHandleSubmit = async () => {
    try {
      setIsSubmitting(true);

      let confirmError, paymentIntent;

      if (paymentDetails?.mode === "payment_intent") {
        const response = await stripe.confirmPayment({
          elements: elements,
          redirect: "if_required",
        });
        confirmError = response.error;
        paymentIntent = response.paymentIntent;
      } else {
        const response = await stripe.confirmSetup({
          elements: elements,
          confirmParams: {
            return_url: ``,
            payment_method_data: {
              billing_details: {
                // name: paymentDetails?.name,
              },
            },
          },
          redirect: "if_required",
        });
        confirmError = response.error;
        paymentIntent = response.setupIntent;
      }

      if (confirmError) {
        toast.error(confirmError.message);
        setIsSubmitting(false);
        return;
      }

      // Check if the payment succeeded
      if (paymentIntent.status !== "succeeded") {
        toast.error("Payment status unknown.");
        setIsSubmitting(false);
        return;
      }

      const response = await apiRequest("POST", `portal/workspaces/:workspace_id/billing/plan/purchase`, {
        body: { payment_method: paymentIntent?.payment_method, payment_id: paymentIntent?.id, interval, on_hold: onHold ? false : true },
        params: { workspace_id: workspaceDetails?._id },
      });
      refresh(workspaceDetails?._id);
      toast.success(response?.data?.message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-full space-y-12">
      <div className="mt-6 space-y-4">
        <div className="relative">
          <PaymentElement />
        </div>
        <div className="relative flex w-full items-center justify-center gap-2">
          <Button
            isDisable={isSubmitting}
            buttonClasses={`!w-1/12 !bg-white !text-highlightColor border !border-highlightColor`}
            buttonIconPosition={"left"}
            buttonIcon={ArrowUturnLeftIcon}
            buttonFunction={() => setView(planDetails?.active_plan?.subscription_status !== "pending" ? "activePlan" : "plan")}
          />
          <Button
            isDisable={isSubmitting}
            buttonClasses={`!bg-highlightColor !w-11/12`}
            buttonIconPosition={"left"}
            buttonIcon={BanknotesIcon}
            buttonLabel={"Pay Now"}
            buttonFunction={onHandleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const CardFormWithStripe = ({ onHold, interval, setView = () => {}, paymentDetails, workspaceDetails, planDetails, refresh = () => {} }) => {
  const stripePromise = loadStripe(paymentDetails?.stripe_publishable_key);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: paymentDetails?.client_secret,
    appearance,
  };

  return (
    <div className="mx-auto h-full w-full bg-white p-4">
      <div>
        <div className="flex w-full items-center gap-3 space-x-2 text-2xl font-semibold">{planDetails?.plan?.name || "Plan Name"}</div>
        <div className="mb-4 py-1 pl-0.5 text-[13px] font-medium text-gray-500">{planDetails?.plan?.description || "No description provided."}</div>
      </div>
      <div className="flex items-center justify-between gap-1 border-b border-t">
        <div className=" rounded-md border border-gray-200 px-3 py-1 font-bold text-highlightColor">
          {planDetails?.plan?.pricing?.rates?.map((rate) => {
            if (rate.interval !== interval) return null;
            const discountedPrice = rate.amount - rate.discount_amount;
            const showDiscount = rate.discount_type !== "none" && rate.discount_percentage > 0;
            return (
              <div
                key={rate._id}
                className="flex items-center justify-between">
                <div>
                  {showDiscount ? (
                    <>
                      <span className="text-gray-400 line-through">{formatCurrency(rate.amount)}</span>
                      <span className="ml-2 text-green-600">{formatCurrency(discountedPrice)}</span>
                    </>
                  ) : (
                    <span>{formatCurrency(rate.amount)}</span>
                  )}
                  <span className="text-base font-medium text-gray-400"> / {rate.interval}</span>
                </div>
                {showDiscount && <span className="ml-2 rounded bg-yellow-100 px-2 py-0.5 text-sm font-medium text-yellow-800">{rate.discount_percentage}% off</span>}
              </div>
            );
          })}
        </div>
        <div className="my-3 flex  items-center justify-between px-2 text-lg font-semibold text-slate-600">
          <div>Amount Payable : </div>
          <div>{`${formatCurrency(planDetails?.plan?.pricing?.rates?.find((rate) => rate?.interval === interval)?.amount - planDetails?.plan?.pricing?.rates?.find((rate) => rate?.interval === interval)?.discount_amount || 0)}`}</div>
        </div>
      </div>
      <div className="space-y-2">
        {planDetails?.plan?.free_trial?.status && !planDetails?.active_plan?.subscription_status === "on_trial" && (
          <div className="rounded-md border border-blue-100 bg-blue-50 px-4 py-2">
            <span className="text-sm font-medium text-blue-600">Free Trial:</span>
            <span className="ml-2 text-lg font-semibold text-blue-800">{planDetails?.plan?.free_trial?.days} days</span>
            <p className="mt-1 text-sm text-blue-700">This plan includes a free trial of {planDetails?.plan?.free_trial?.days} days. After the trial ends, your subscription will continue automatically.</p>
          </div>
        )}
      </div>
      <Elements
        options={options}
        stripe={stripePromise}>
        <CardForm
          refresh={refresh}
          setView={setView}
          onHold={onHold}
          interval={interval}
          paymentDetails={paymentDetails}
          workspaceDetails={workspaceDetails}
          planDetails={planDetails}
        />
      </Elements>
    </div>
  );
};

export default CardFormWithStripe;
