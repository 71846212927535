import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import BrowserEmbedError from "src/components/embedTypes/BrowserEmbedError";
import ContentError from "src/components/embedTypes/ContentError";
import ContentLoading from "src/components/embedTypes/ContentLoading";
import ContentTypesRouter from "src/components/embedTypes/ContentTypesRouter";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import useBrowserType from "src/hooks/useBrowserType";
import useCheckThirdPartyCookies from "src/hooks/useCheckThirdPartyCookies";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";

const Dashboard = () => {
  const { details: workspaceDetails, menuType, setWorspacePaymentModalOpen = () => {}, isPlanDetailsLoaded } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const navigate = useNavigate();
  const { workspace_id } = useParams();
  const { group, page, pfilters } = useUrlQueryParams();

  const { thirdPartyCookiesEnabled, cookieCheckComplete } = useCheckThirdPartyCookies();
  const browser = useBrowserType();

  const [loadingPage, setLoadingPage] = useState(true);
  const [content, setContent] = useState(null);
  const [contentError, setContentError] = useState(null);
  const [browserError, setBrowserError] = useState(null);

  const pageErrorTypes = {
    page_error: {
      title: "Reload Page",
      subtitle: "Oops... there was a loading error.",
      secondary: "Something went wrong, please try later.",
    },
    payment_error: {
      title: "Reload Page",
      subtitle: "This site is currently down .",
      secondary: "Please contact your administrator for more details",
    },
    incorrect_credentials: {
      title: "Reload Page",
      subtitle: "Oops... there was a loading error.",
      secondary: "It appears the credentials that were added were invalid.",
    },
    incorrect_filters: {
      title: "Reload Page",
      subtitle: "Oops... there was a loading error.",
      secondary: "It appears the filters that were added were invalid or not filled out.",
    },
  };

  useEffect(() => {
    const handlePageGet = async () => {
      try {
        localStorage.setItem("workspace", workspace_id || "");
        localStorage.setItem("group", group || "");
        localStorage.setItem("page", page || "");

        setContentError(null);
        setLoadingPage(true);

        const { data: contentResponseData } = await apiRequest(
          "GET",
          `/portal/embed/:workspace_id`,
          {
            params: { workspace_id },
            queries: { group, page, pfilters },
          },
          { returnErrorWithstatus: true },
        );

        const { data } = contentResponseData;

        if (data?.invalidWorkspace) {
          navigate("/permission-denied");
        } else if (data?.invalidPage || data?.invalidGroup) {
          const { data: resData } = await apiRequest("GET", `/portal/users/${workspaceDetails?._id}/page`);
          navigate(resData.link);
        } else if (data?.invalidFilter) {
          setContentError("incorrect_filters");
        } else {
          setContent(data);
          setLoadingPage(false);
        }
      } catch (error) {
        if (error.message !== "canceled") {
          setLoadingPage(false);
          if (error?.status === 402) {
            setContentError("payment_error");
          } else {
            setContentError("page_error");
          }
        }
      }
    };

    if (workspace_id && (workspaceDetails?.workspace_type === "JWT_FULL_EMBED" || page) && isPlanDetailsLoaded) {
      handlePageGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, page, workspace_id, workspaceDetails?.workspace_type, pfilters, navigate, setWorspacePaymentModalOpen, isPlanDetailsLoaded]);

  useEffect(() => {
    // Check if cookie check is complete
    if (cookieCheckComplete) {
      if (!browser) return;
      if (content?.config?.type !== "JWT_FULL_EMBED") return;

      const shouldShowBrowserError = () => {
        if (!thirdPartyCookiesEnabled) return "3PartyCookiesNotEnabled";
        if (!browser.includes("Chrome", "Edge")) return "NotUsingChromeOrEdge";
        return false;
      };

      let errorMessage = shouldShowBrowserError();
      setBrowserError(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieCheckComplete, browser, thirdPartyCookiesEnabled]);

  // Add the message listener here
  useEffect(() => {
    const ports = {};
    const handleMessage = (event) => {
      // console.log("Received message on window", event);

      // Check if event contains a message port
      if (!event.ports[0]) return;

      const referenceId = event.data.referenceId;
      const port = event.ports[0];
      port.start();

      // Listen for messages on the port
      const onPortMessage = (e) => {
        // console.log("Received message from iframe:", e.data);

        if (e.data.method) {
          // console.log(`RPC method: ${e.data.method}`);
        }

        if (e.data.result) {
          // console.log("RPC result:", e.data.result);
        }

        if (e.data.error) {
          // console.log("RPC error:", e.data.error);
        }
      };

      port.onmessage = onPortMessage;
      ports[referenceId] = port;
    };

    window.addEventListener("message", handleMessage);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      {contentError || browserError ? (
        browserError ? (
          <BrowserEmbedError
            browser={browser}
            cookiesEnabled={thirdPartyCookiesEnabled}
          />
        ) : (
          <ContentError
            pageErrorTypes={pageErrorTypes}
            pageError={contentError}
          />
        )
      ) : (
        <>
          {loadingPage ? (
            <ContentLoading menuType={menuType} />
          ) : (
            <div className="w-full">
              <ContentTypesRouter
                content={content}
                menuType={menuType}
                workspace={workspaceDetails}
                site={site}
                setError={() => {}}
                setLoading={() => {}}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
