import CardFormWithStripe from "src/components/payments/CardFormWithStripe";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import AssignedPlan from "src/components/payments/AssignedPlan";
import React, { useContext, useEffect, useState } from "react";
import { WorkspaceContext } from "src/context/workspace";
import ActivePlan from "src/components/payments/ActivePlan";

const ManagePayments = ({ loading, loadPlanDetails }) => {
  const { details: workspaceDetails, planDetails } = useContext(WorkspaceContext);

  const [interval, setInterval] = useState("year");
  const [onHold, setOnHold] = useState(false);
  const [view, setView] = useState("plan");
  const [paymentDetails, setPaymentDetails] = useState({});

  useEffect(() => {
    if (planDetails?.active_plan && planDetails?.active_plan?.subscription_status === "pending") {
      setView("plan");
    } else {
      setView("activePlan");
    }
  }, [planDetails]);

  // const tabs = [
  //   {
  //     name: "Overview",
  //     component: planDetails?.active_plan && planDetails?.active_plan?.subscription_status !== "pending" && (
  //       <ActivePlan
  //         setView={setView}
  //         interval={interval}
  //         setInterval={setInterval}
  //         plan={planDetails?.active_plan}
  //         loadPlanDetails={loadPlanDetails}
  //         setPaymentDetails={setPaymentDetails}
  //       />
  //     ),
  //   },
  // {
  //   name: "Settings",
  //   component: <></>,
  // },
  // {
  //   name: "Invoices",
  //   component: <></>,
  // },
  // {
  //   name: "Payment Menthod",
  //   component: <></>,
  // },
  // {
  //   name: "Billing Contacts",
  //   component: <></>,
  // },
  // ];

  return (
    <>
      {loading ? (
        <div className="flex min-h-[300px] w-full items-center justify-center">
          <Preloader />
        </div>
      ) : (
        <>
          {planDetails ? (
            <>
              <div className="flex h-full min-h-[300px] w-full justify-center gap-5  ">
                {view === "plan" ? (
                  <>
                    {planDetails?.active_plan && ["pending"].includes(planDetails?.active_plan?.subscription_status) && (
                      <AssignedPlan
                        setView={setView}
                        interval={interval}
                        setInterval={setInterval}
                        setPaymentDetails={setPaymentDetails}
                        workspaceDetails={workspaceDetails}
                        activePlan={planDetails?.active_plan}
                        plan={planDetails?.active_plan?.plan_details}
                      />
                    )}
                  </>
                ) : view === "payForm" ? (
                  <CardFormWithStripe
                    setView={setView}
                    onHold={onHold}
                    interval={interval}
                    refresh={loadPlanDetails}
                    planDetails={planDetails}
                    paymentDetails={paymentDetails}
                    workspaceDetails={workspaceDetails}
                  />
                ) : (
                  view === "activePlan" &&
                  planDetails?.active_plan &&
                  planDetails?.active_plan?.subscription_status !== "pending" && (
                    <ActivePlan
                      onHold={onHold}
                      setView={setView}
                      interval={interval}
                      setOnHold={setOnHold}
                      setInterval={setInterval}
                      activePlan={planDetails?.active_plan}
                      plan={["on_trial"].includes(planDetails?.active_plan?.subscription_status) ? planDetails?.active_plan?.plan_details : planDetails?.plan}
                      loadPlanDetails={loadPlanDetails}
                      setPaymentDetails={setPaymentDetails}
                    />
                  )
                )}
              </div>
            </>
          ) : (
            <div className="flex w-full flex-col items-center justify-center px-2 py-12 sm:px-6 lg:px-8">
              <div className="flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 sm:text-3xl">This site is currently down .</h2>
                <div className="text-regular mt-1 text-center text-gray-600">Please contact your administrator for more details</div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ManagePayments;
