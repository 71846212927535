import { ForwardIcon } from "@heroicons/react/24/outline";
import { apiRequest } from "src/async/apiUtils";
import { formatCurrency } from "src/helpers";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../form/Button";
import Toggle from "../form/Toggle";

const AssignedPlan = ({ plan, workspaceDetails, interval, setPaymentDetails, setView, setInterval, showFreeTrial, activePlan }) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubscribe = async () => {
    try {
      setButtonLoading(true);
      const response = await apiRequest("post", `/portal/workspaces/:workspace_id/billing/payment/intent`, { body: { interval }, params: { workspace_id: workspaceDetails?._id } });
      if (response?.data?.status === 200) {
        setPaymentDetails(response?.data?.data);
        setView("payForm");
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div className="relative mb-4 h-full w-full  p-4">
      <div className="mt-4">
        <div className="flex w-full items-center gap-2 text-4xl font-semibold">{plan?.name}</div>
        <div className="py-1 pl-0.5 text-sm opacity-75">{plan?.description}</div>
      </div>
      <div className="relative my-3 flex w-full items-center justify-between rounded-lg border border-gray-200 bg-white px-4 py-3">
        <div className="flex items-center gap-2">
          <Toggle
            checked={interval === "month"}
            onChange={() => setInterval(interval === "month" ? "year" : "month")}
          />
          <div className="flex gap-1">Switch to monthly billing</div>
        </div>
        <div className="flex gap-1">
          <div className="!mt-auto rounded-md border border-gray-200 px-3 py-1 font-bold text-highlightColor">
            {plan?.pricing?.rates?.map((rate) => {
              if (rate.interval !== interval) return null;
              const discountedPrice = rate.amount - rate.discount_amount;
              const showDiscount = rate.discount_type !== "none" && rate.discount_percentage > 0;
              return (
                <div
                  key={rate._id}
                  className="flex items-center justify-between">
                  <div>
                    {showDiscount ? (
                      <>
                        <span className="text-gray-400 line-through">{formatCurrency(rate.amount)}</span>
                        <span className="ml-2 text-green-600">{formatCurrency(discountedPrice)}</span>
                      </>
                    ) : (
                      <span>{formatCurrency(rate.amount)}</span>
                    )}
                    <span className="text-base font-medium text-gray-400"> / {rate.interval}</span>
                  </div>
                  {showDiscount && <span className="ml-2 rounded bg-yellow-100 px-2 py-0.5 text-sm font-medium text-yellow-800">{rate.discount_percentage}% off</span>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end border-t pt-3">
        <Button
          isDisable={buttonLoading}
          buttonClasses="!bg-highlightColor"
          buttonIconPosition="right"
          buttonIcon={ForwardIcon}
          buttonLabel="Proceed To Payment"
          buttonFunction={handleSubscribe}
        />
      </div>
    </div>
  );
};

export default AssignedPlan;
