import { getStatusStyles } from "src/helpers/planHelper";
import { classNames } from "src/helpers/classNames";
import { apiRequest } from "src/async/apiUtils";
import { formatCurrency } from "src/helpers";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Modal from "../shared/Modal";
import Button from "../form/Button";
import moment from "moment";

const UpcomingPlan = ({ activePlan, workspaceDetails, loadPlanDetails }) => {
  const { className: upcomingClassName, label: upcomingLabel } = getStatusStyles("upcoming");
  const [planActiveModal, setPlanActiveModal] = useState(false);
  const [isActivating, setActivating] = useState(false);

  const handleActivate = async () => {
    try {
      setActivating(true);
      const response = await apiRequest("put", `portal/workspaces/:workspace_id/billing/plan/active`, { params: { workspace_id: workspaceDetails?._id } });
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        loadPlanDetails(workspaceDetails?._id, true);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setActivating(false);
    }
  };

  return (
    <div className="rounded-lg border p-2 px-4">
      <div className="mb-2 flex justify-between border-b pb-1 font-semibold">
        <span>Upcoming Plan</span>
        <span className="text-sm font-medium text-slate-600">
          Will automatically get activated on <span className="font-semibold">{moment(activePlan?.upcoming_plan?.start_date).format("MMM DD, YYYY")}</span>
        </span>
      </div>
      <div className="flex items-start justify-between gap-3 ">
        <div>
          <div className="flex w-full items-center gap-2 text-lg font-semibold">
            {activePlan?.upcoming_plan?.plan_details?.name}
            {<div className={classNames("flex h-6 items-center justify-center gap-2 rounded border px-2 text-sm", upcomingClassName)}>{upcomingLabel}</div>}
          </div>
          <div className="py-1 pl-0.5 text-sm opacity-75">{activePlan?.upcoming_plan?.plan_details?.description}</div>
        </div>
        <Button
          isDisable={isActivating}
          buttonClasses="bg-highlightColor !w-1/2"
          buttonLabel="Activate Now"
          buttonFunction={() => setPlanActiveModal(true)}
        />
      </div>
      <div className="!mt-auto inline-block rounded-md border p-1  px-2 text-sm font-semibold text-highlightColor">
        {`${formatCurrency(activePlan?.upcoming_plan?.plan_details?.pricing?.rates?.find((rate) => rate?.interval === activePlan?.upcoming_plan?.subscription?.recurring_type)?.amount - activePlan?.upcoming_plan?.plan_details?.pricing?.rates?.find((rate) => rate?.interval === activePlan?.upcoming_plan?.subscription?.recurring_type)?.discount_amount || 0)}`}
        <span className="font-medium text-gray-400"> / {activePlan?.upcoming_plan?.subscription?.recurring_type}</span> | Billed {activePlan?.upcoming_plan?.subscription?.recurring_type}ly
      </div>
      <PlanActiveConfirmModal
        isOpen={planActiveModal}
        disable={isActivating}
        onSuccess={handleActivate}
        onCancel={() => setPlanActiveModal(false)}
      />
    </div>
  );
};

export default UpcomingPlan;

const PlanActiveConfirmModal = ({ isOpen = false, disable = false, onSuccess = () => {}, onCancel = () => {} }) => {
  return (
    <Modal
      title={"Subscription"}
      secondaryTitle="Activate"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onSuccess}
      defaultOptions={{
        onSuccessButtonText: "Yes",
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart: disable,
        onCancelButtonVisible: true,
        onCancelButtonText: "No",
      }}>
      <div className="grid gap-y-8 whitespace-nowrap font-medium text-gray-500">Are you sure you want to activate the subscription now ?</div>
    </Modal>
  );
};
