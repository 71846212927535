export const getStatusStyles = (subscriptionStatus) => {
  const statusStyles = {
    pending: {
      className: "border border-yellow-200 bg-yellow-100 text-yellow-500",
      label: "Pending",
    },
    active: {
      className: "border border-green-200 bg-green-100 text-green-500",
      label: "Active",
    },
    on_trial: {
      className: "border border-blue-200 bg-blue-100 text-blue-500",
      label: "On Trial",
    },
    inactive: {
      className: "border border-yellow-200 bg-yellow-100 text-yellow-500",
      label: "Inactive",
    },
    expired: {
      className: "border border-gray-200 bg-gray-100 text-gray-500",
      label: "Expired",
    },
    canceled: {
      className: "border border-red-200 bg-red-100 text-red-500",
      label: "Canceled",
    },
    on_hold: {
      className: "border border-orange-200 bg-orange-100 text-orange-500",
      label: "On Hold",
    },
    upcoming: {
      className: "border border-orange-200 bg-orange-100 text-orange-500",
      label: "Upcoming",
    },
  };

  return (
    statusStyles[subscriptionStatus] || {
      className: "border border-gray-200 bg-gray-100 text-gray-500",
      label: "Unknown Status",
    }
  );
};
