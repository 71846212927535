import { XMarkIcon } from "@heroicons/react/24/outline";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { getStatusStyles } from "src/helpers/planHelper";
import { WorkspaceContext } from "src/context/workspace";
import { classNames } from "src/helpers/classNames";
import React, { useContext, useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import PlanResubscribe from "./PlanResubscribe";
import { formatCurrency } from "src/helpers";
import { toast } from "react-toastify";
import Button from "../form/Button";
import moment from "moment";
import Modal from "../shared/Modal";
import UpcomingPlan from "./UpcomingPlan";

const ActivePlan = ({ activePlan, plan, onHold, setOnHold, interval, setInterval, loadPlanDetails, setPaymentDetails = () => {}, setView = () => {} }) => {
  const { details: workspaceDetails } = useContext(WorkspaceContext);
  const [cancelModal, setCancelModalOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const { className, label } = getStatusStyles(activePlan?.subscription_status);

  const handleCancel = async () => {
    try {
      setDeleting(true);
      const response = await apiRequest("delete", `/portal/workspaces/:workspace_id/billing/plan/cancel`, { params: { workspace_id: workspaceDetails?._id } });
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        loadPlanDetails(workspaceDetails?._id, true);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setCancelModalOpen(false);
      setDeleting(false);
    }
  };

  return (
    <div className="relative mb-4 flex h-full w-full flex-col space-y-4 bg-white  p-4">
      <div className="rounded-lg border p-4">
        <div className="">
          <div className="mb-4 flex items-center justify-between border-b pb-2">
            <div className="font-semibold">Current Plan</div>
            {activePlan?.free_trial_days_left >= 0 && (
              <div className="flex flex-shrink-0 items-baseline gap-1 whitespace-nowrap">
                <span className="text-sm font-semibold text-gray-500">Free Trial:</span>
                <span className="ml-2 text-sm font-semibold text-highlightColor">{activePlan?.free_trial_days_left} day(s) left</span>
              </div>
            )}
          </div>
          <div className="mb-1 flex w-full items-center gap-2 text-2xl font-semibold">
            {plan?.name}
            {<div className={classNames("flex h-6 items-center justify-center gap-2 rounded-md px-2 text-sm", className)}>{label}</div>}
          </div>
          <div className="mb-4 py-1 pl-0.5 text-[13px] font-medium text-gray-500">{plan?.description}</div>
        </div>
        <div className="flex items-center justify-between gap-1 pt-2">
          <div className="!mt-auto rounded-md border border-gray-200 px-3 py-1 text-lg font-bold text-highlightColor">
            {`${formatCurrency(plan?.pricing?.rates?.find((rate) => rate?.interval === activePlan?.subscription?.recurring_type)?.amount - plan?.pricing?.rates?.find((rate) => rate?.interval === activePlan?.subscription?.recurring_type)?.discount_amount || 0)}`}
            <span className="text-base font-medium text-gray-400"> / {activePlan?.subscription?.recurring_type}</span> <span className="text-base font-semibold text-gray-500">| {"Billed " + activePlan?.subscription?.recurring_type + "ly"}</span>
          </div>
          <div className="flex items-center justify-center gap-2 rounded-md px-2">
            <div className="flex items-center justify-center text-base font-semibold text-gray-500">
              {["canceled", "expired", "on_trial"].includes(activePlan?.subscription_status) ? (moment(activePlan?.end_date).format("MM-DD-YYYY") >= moment(new Date()).format("MM-DD-YYYY") ? "Expiring on:" : "Expired on:") : "Next Billing Date:"}
            </div>
            <div className="flex items-center justify-center gap-1 font-bold text-highlightColor">
              <CalendarIcon className="h-6 w-6" />
              {moment(activePlan?.end_date).format("MMM DD, YYYY")}
            </div>
          </div>
        </div>
        {!["canceled", "expired", "on_trial"].includes(activePlan?.subscription_status) && (
          <div className="my-3 flex items-baseline justify-between border-t pt-4 text-lg">
            <Button
              buttonClasses="bg-red-600 text-white"
              buttonIconPosition="left"
              buttonIcon={XMarkIcon}
              buttonLabel={"Cancel Subscription"}
              buttonLabelClasses={"font-medium"}
              buttonFunction={() => setCancelModalOpen(true)}
            />
          </div>
        )}
      </div>

      {activePlan?.upcoming_plan && (
        <UpcomingPlan
          activePlan={activePlan}
          loadPlanDetails={loadPlanDetails}
          workspaceDetails={workspaceDetails}
        />
      )}
      {!activePlan?.upcoming_plan && ["on_trial", "canceled", "expired"].includes(activePlan?.subscription_status) && (
        <PlanResubscribe
          plan={plan}
          onHold={onHold}
          setView={setView}
          interval={interval}
          setOnHold={setOnHold}
          activePlan={activePlan}
          setInterval={setInterval}
          workspaceDetails={workspaceDetails}
          setPaymentDetails={setPaymentDetails}
        />
      )}
      <PlanCancelModal
        isOpen={cancelModal}
        disable={isDeleting}
        onSuccess={handleCancel}
        workspaceName={workspaceDetails?.name}
        onCancel={() => setCancelModalOpen(false)}
        endDate={moment(activePlan?.end_date).format("MMM DD, YYYY")}
      />
    </div>
  );
};

export default ActivePlan;

const PlanCancelModal = ({ isOpen = false, disable = false, onSuccess = () => {}, onCancel = () => {}, workspaceName, endDate }) => {
  return (
    <Modal
      title={"Subscription"}
      secondaryTitle="Cancel"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onSuccess}
      defaultOptions={{
        onSuccessButtonText: "Yes",
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart: disable,
        onCancelButtonVisible: true,
        onCancelButtonText: "No",
      }}>
      <div className="mb-2 text-base text-highlightColor">
        If you cancel the current subscription, your access to <span className="font-semibold">{workspaceName}</span> will end after <span className="font-semibold">{endDate} , </span> Are you sure you want to cancel the subscription?
      </div>
    </Modal>
  );
};
